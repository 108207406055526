import { dom, library } from "@fortawesome/fontawesome-svg-core";
import { faUnlockAlt } from "@fortawesome/free-solid-svg-icons/faUnlockAlt";
import firebase from "firebase/app";
import "firebase/auth";
import $ from "jquery";
export default (window.$ = window.jQuery = $);
library.add(faUnlockAlt);
dom.i2svg();
const app = firebase.initializeApp({
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  projectId: process.env.PROJECT_ID,
});
const urlParams = new URLSearchParams(window.location.search);
if (urlParams.get("oobCode")) {
  const api = app.auth();
  api
    .verifyPasswordResetCode(urlParams.get("oobCode"))
    .then(function(val) {
      $("input").keyup(function() {
        if (
          $("#password").val() == $("#passwordConfirm").val() &&
          $("#passwordConfirm").val() != ""
        ) {
          $("#submit").removeAttr("disabled");
          $(".text-danger").attr("hidden", "hidden");
        } else {
          $("#submit").attr("disabled", "disabled");
          $(".text-danger").removeAttr("hidden");
        }
      });
    })
    .catch(function(val) {
      console.log("hola");
      $("#success").remove();
      $("#wrong").removeAttr("hidden");
      switch (val.code) {
        case "auth/expired-action-code":
          $("#text").text(
            "El codigo ya ha expirado, solicita una nuevo cambio de contraseña."
          );
          break;
        case "auth/invalid-action-code":
          $("#text").text(
            "El codigo es incorrecto o la contraseña ya ha sido restablecida."
          );
          break;
        case "auth/user-disabled":
          $("#text").text("Tu usuario se encuentra inhabilitado.");
          break;
        case "auth/user-not-found":
          $("#text").text("No se puede encontrar tu usuario.");
          break;
      }
    });
  $("#submit").click(function() {
    api
      .confirmPasswordReset(
        urlParams.get("oobCode"),
        $("#passwordConfirm").val()
      )
      .then(function(val) {
        $("#success").remove();
        $("#wrong").attr("hidden", "hidden");
        $("#textSuccess").text("La contraseña ha sido cambiada exitosamente");
        $("#successOperation").removeAttr("hidden");
      })
      .catch(function(val) {
        if (val.code == "auth/weak-password") {
          $("#wrong").removeAttr("hidden");
          $("#text").text("La contraseña es muy débil.");
        }
      });
  });
} else {
  $("#success").remove();
  $("#wrong").removeAttr("hidden");
  $("#text").text(
    "La página no cuenta con la información necesaria, solicita nuevamente la reposición de la contraseña"
  );
}
